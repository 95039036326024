import React from 'react';
import { cssTransition, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './src/assets/scss/style.scss';

export const wrapRootElement = ({ element }) => {
    return (
        <>
            <ToastContainer 
                position='bottom-center'
                autoClose={7500}    // Display timeout in ms
                style={{ textAlign: 'center' }}
                theme='dark'
                // transition={toastTransition}
            />
            {element}
        </>
    )
}

// Perform global actions here
export const onInitialClientRender = () => {
    // Load the reCAPTCHA API site-wide (this is blocking)
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.onload = () => {
            console.log('reCaptcha loaded successfully');
            resolve();
        };
        script.onerror = (error) => {
            console.error('Failed to load reCAPTCHA script:', error);
            resolve();
        };
        document.body.appendChild(script);
    })
};

// Workaround Gatsby 5 bug where Link components sometimes don't scroll to the top of the destination page
// See https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    // Abort if the new location has a hash (ie. don't scroll to the top for anchor links)
    if (location.hash) {
        return true;
    }

    // Retrieve the saved scroll position, if available
    const currentPosition = getSavedScrollPosition(location);

    // Delay scrolling to the top of the page
    setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);

    // Prevent default scroll behavior
    return false;
};
